import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faInstagram, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
import {  faEnvelope , faEnvelopeSquare, faPhoneAlt,  faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF , faWhatsapp   } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './App.css';
library.add(fab,  )
function App() {
  return (
    <div className="App">
     <h1>REAL GREEN SPICE</h1>
<i>GET IN TOUCH</i>
<a href="https://api.whatsapp.com/send?phone=917907453627&text=Enter%20Your%20Query" target="_blank" rel="noreferrer" class="fa fa-github"><FontAwesomeIcon icon={faWhatsapp} /></a>
<a href="https://www.facebook.com/realgreenspice/" target="_blank" rel="noreferrer" class="fa fa-facebook"><FontAwesomeIcon icon={faFacebookF} /></a>
<a href="https://www.instagram.com/" target="_blank" rel="noreferrer" class="fa fa-twitter"><FontAwesomeIcon icon={faInstagram} /></a>
<a href="mailto:contact@realgreenspice.com" target="_blank" rel="noreferrer" class="fa fa-behance"><FontAwesomeIcon icon={faEnvelope} /></a>
<a href="tel:+919947880840" target="_blank" rel="noreferrer" class="fa fa-codepen"><FontAwesomeIcon icon={faPhoneAlt} /></a>
<div className="read-contact">
  <a href="tel:+919947880840" target="_blank" rel="noreferrer">
    <h3><FontAwesomeIcon icon={faPhoneAlt} />   +91 99478 80840</h3>
  </a>
  <a href="https://api.whatsapp.com/send?phone=917907453627&text=Enter%20Your%20Query" target="_blank" rel="noreferrer">
    <h3><FontAwesomeIcon icon={faWhatsapp} />   +91 79074 53627</h3>
  </a>
  <a href="mailto:contact@realgreenspice.com" target="_blank" rel="noreferrer">
    <h3><FontAwesomeIcon icon={faEnvelope} />   contact@realgreenspice.com</h3>
  </a>
</div>  

<footer><h2 className="copyright">&copy; {new Date().getFullYear()} Real Green Spice. All rights reserved.</h2></footer>
    </div>
  );
}

export default App;
